import { useEffect } from "react";
import * as sec from '@vf-org/smapi-js';

export const useSmapi = (smapiStatus) => {
  useEffect(() => {
          /*[Important Note] 
            please not that the blew line is for smapi testing to access sec from window object
            and it should be removed after smapi testing team finish the report   
          */
          window.sec=sec;

          if(smapiStatus) {
            sec.init({
            enabled: true,
            environment: 'GCP_PRE',
            verbose: false,
            cookiesEnabled: true,
            networkMonitoringEnabled: true,
            spaTrackingOptions: {
             enabled: true,
             replaceState: false,
           } ,
           headers: {
             'x-vf-trace-source': 'js:com.vodafone.lib.sec.smapiweb',
             'x-vf-trace-source-version': 'smapi-web-test',
           },
           customerChannel: {
            enableAppRating : true, /* if true the App rating will be enabled else the bug report will be disabled*/
           }
         });
        }
        },[smapiStatus]);
        console.log('SMAPI Object: ', sec);  
    

}